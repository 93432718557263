<template> 
    <div class="page-content"> 
        <div class="grid"> 
            <div class="row no-gutters page-list-header"> 
                <div class="col l-6 m-6 c-12"> 
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách log</span></h4> 
                </div> 
                <div class="col l-6 m-6 c-12"> 
                    <div class="page-list-action">   
                        <md-button v-on:click="refresh()" class="md-icon-button md-dense md-raised md-primary"> 
                            <md-icon>cached</md-icon> 
                            <md-tooltip >Cập nhật</md-tooltip> 
                        </md-button> 
                    </div> 
                </div> 
            </div> 
            <div class="page-list-body"> 
                <div class="table-content"> 
                    <table class="data-table"> 
                        <thead> 
                            <tr> 
                                <th style="width:100px;">#</th> 
                                <th style="width:15%;">Tiêu đề</th> 
                                <th style="width:15%;">Request Url</th> 
                                <th style="width:55%;">Error Message</th>
                                <th style="width:10%;">Ngày tạo</th>
                                <th style="width:10%;">Hành động</th> 
                            </tr> 
                         </thead> 
                        <tbody v-if="loadding == true" style="height: 150px;"> 
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr> 
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr> 
                        </tbody> 
                        <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                            <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                        </tbody> 
                        <tbody v-if="loadding == false && pager.totalItems > 0"> 
                            <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                <td class="center">{{index + 1}}</td> 
                                <td class="center">{{item.title}}</td> 
                                <td>{{item.requestUrl}}</td> 
                                <td>{{item.errorMessage}}</td> 
                                <td class="center">{{item.createdTime}}</td> 
                                <td class="action"> 
                                    <router-link :to="'/log/crud/' + item.id"> 
                                        <md-button class="md-fab md-mini md-second"> 
                                            <md-icon>edit</md-icon> 
                                        </md-button> 
                                    </router-link> 
                                    <md-button v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain"> 
                                        <md-icon>clear</md-icon> 
                                    </md-button> 
                                </td> 
                            </tr> 
                        </tbody> 
                    </table> 
                </div> 
                 <div class="table-footer" v-if="pager.totalPages > 1">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">Trang đầu</a>
                                    <a @click="onPage(search.pageIndex - 1)">Trang trước</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>Page</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">of <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">Rows/page:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">Xem:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> bản ghi</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">Trang sau</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">Trang cuối</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div> 
    </div> 
</template>
<script> 
    import logService from '../../../api/logService'; 
    import messageBox from '../../../utils/messageBox'; 
    import common from '../../../mixins'; 
    import { mapActions } from 'vuex'; 

    export default { 
        metaInfo: { 
            title: 'Danh sách log' 
        }, 
        data() { 
            return { 
               loadding: false, 
               search: { pageIndex: 1, pageSize: common.pageSize }, 
               pager: { totalPages: 1 }, 
               data: [], 
               rowPerPageOptions: [], 
               id: 0 
            } 
        }, 
        created(){ 
            this.rowPerPageOptions = common.rowPerPageOptions; 
            if(this.$route.query.page != undefined){ 
               this.search.pageIndex = parseInt(this.$route.query.page); 
            } 
            if(this.$route.query.pageSize != undefined){ 
               this.search.pageSize = parseInt(this.$route.query.pageSize); 
            } 
            this.filter(); 
        }, 
        methods: { 
            ...mapActions('common', ['setLoading']), 

            onPage(page){ 
               if(page > this.pager.totalPages){ 
                   return; 
               } 
               if(page == 0){ 
                   return; 
               } 
               this.search.pageIndex = page; 
               this.getData(); 
            }, 

            refresh(){ 
               this.search = { pageIndex: 1, pageSize: common.pageSize }; 
               this.getData(); 
            }, 

            filter(){ 
               let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize; 
               this.$router.push('/log?' + url).catch(()=>{}); 

               this.getData(); 
            }, 

            getData(){ 
               this.loadding = true; 
               logService.filter(this.search).then((response) => { 
                   if(response.statusCode == 200){ 
                       this.data = response.data.items; 
                       this.pager = response.data.pager; 
                   } 
                   else{ 
                       messageBox.showWarning(response.data); 
                   } 
                }).finally(() => { this.loadding = false; }); 
            }, 

            confirmDelete(id){ 
               this.$modal.show('dialog', { 
               title: 'Thông báo', 
               text: 'Bạn có chắc muốn xóa không?', 
               buttons: [ 
                       { 
                           title: 'Hủy', 
                           handler: () => { 
                               this.$modal.hide('dialog') 
                           } 
                       }, 
                       { 
                           title: 'Xóa', 
                           handler: () => { 
                               this.del(id) 
                           } 
                       } 
               ] 
               }) 
            }, 

            del(id){ 
               this.$modal.hide('dialog'); 
               this.setLoading(true); 
               logService.delete(id).then((response) => { 
                   if(response.statusCode == 200){ 
                       messageBox.showMessage("Xóa thành công"); 
                       this.refresh(); 
                   } 
                   else{ 
                       messageBox.showWarning(response.data); 
                   } 
                }).finally(() => { this.setLoading(false); }); 
            }, 

        }, 
        watch: { 
            'search.pageIndex': function () { 
                this.filter(); 
            }, 
            'search.pageSize': function () { 
                this.search.pageIndex = 1; 
                this.filter(); 
            } 
        }, 
    } 

</script> 
